@import "./colors.css";

.mainBody{
    height: 100vh;
    overflow-Y: auto;
}
.mainHeader{
    min-height: 100px;
}

.noPadding {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
}

.pluginItem:hover {
    background-color: #007bff17;
}

.pluginItem.selectedPlugin {
    border: 1px solid #007bff56;
}

.pluginList{
    border-radius: 0px;
}

.enableConfig {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.disableConfig{
    cursor: not-allowed;
    font-size: 1.5rem;
    color: gray;
}

.floatRight{
    float: right;
}

.margin_top_1 {
    margin-top: 1rem;
}

.permissionToggles{
    min-width: 100px;
}

.sy_admin_manager_mtr {
    margin-top: 1rem;
}
.sy_admin_manager_float_right{
    float: right;
}